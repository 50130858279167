<template>
    <div align="left">

        <div v-if="title !== ''" class="text-h5 q-mt-sm q-mb-xs">{{ title }}</div>

        <div v-if="sub_title !== '' " class="text-caption text-grey">{{ sub_title }}</div>

        <div class="q-gutter-md" v-if="!info_cellulare.is_certificato || !certificazione">
            <br>
            <q-input outlined
                type="number"
                v-model="cellulare"
                label="Numero di cellulare del Cliente"
                :disable="!enable_change_num"
                reactive-rules
                :rules="[ val => val.length < 11 || 'Il numero di cellulare e` formalmente errato. Non inserire il codice internazionale +039' ]"

            >
                <template v-slot:after>
                    <q-btn
                        :label="label_invia_otp"
                        color="primary"
                        size="lg"
                        style="min-width: 160px;"
                        :disabled="disattiva_pulsante_invia_otp"
                        @click.native="onInviaOtp"
                    />
                </template>
            </q-input>

            <div v-if="otp_inviato && certificazione">

                <template v-if="isSocieta">
                    <q-input outlined
                        type="text"
                        v-model="rappresentante_legale"
                        label="Nominativo del Rappresentante Legale"
                        reactive-rules
                        :rules="[ val => val !== '' || 'Il nominativo del Rappresentante Legale è obbligatorio' ]"
                    />

                    <q-checkbox
                        size="xs"
                        v-model="cliente_rappresentante_legale"
                        val="xs"
                        :label="'Dichiaro che il cliente è il Rappresentante Legale della Società '+NominativoCliente"
                        :disable="disattiva_dichiarazioni"
                    />
                </template>

                <q-checkbox
                    size="xs"
                    v-model="cliente_davanti"
                    val="xs"
                    label="Di aver identificato direttamente il Cliente"
                    :disable="disattiva_dichiarazioni"
                />
                <br>
                <q-checkbox
                    size="xs"
                    v-model="cliente_ricevuto_otp"
                    val="xs"
                    label="Dichiaro che ha ricevuto l'sms OTP sul suo cellulare"
                    :disable="disattiva_dichiarazioni"
                />
            </div>

            <q-input outlined
                v-if="verifica_is_visible && otp_inviato"
                type="number"
                v-model="codice_otp"
                label="Codice OTP ricevuto"
            >

                <template v-slot:after>
                    <q-btn v-if="certificazione"
                        :label="label_verifica"
                        color="primary"
                        size="lg"
                        style="min-width: 160px;"
                        :disabled="disattiva_pulsante_verifica_otp || !cliente_davanti || !cliente_ricevuto_otp || !cliente_rappresentante_legale"
                        @click.native="onVerificaOtp"
                    />

                    <q-btn v-if="!certificazione"
                        :label="label_verifica"
                        color="primary"
                        size="lg"
                        style="min-width: 160px;"
                        :disabled="disattiva_pulsante_verifica_otp"
                        @click.native="onVerificaOtp"
                    />
                </template>
            </q-input>

            <div v-if="verifica_avviata">
                <div class="row" v-show="!is_otp_valid">
                    <q-icon name="warning" class="text-red" style="font-size: 3rem; margin-right: 6px;" />
                    <div style="padding-top: 12px;">ATTENZIONE!! {{ errore_verifica_cellulare }}</div>
                </div>
                <div class="row" v-show="is_otp_valid">
                    <q-icon name="mdi-check-outline" class="text-green" style="font-size: 3rem; margin-right: 6px;" />
                    <div style="padding-top: 12px;">Il codice OTP &egrave; corretto</div>
                </div>
            </div>
        </div>

        <div class="q-gutter-md" v-if="info_cellulare.is_certificato && certificazione">
            <div class="text-center justify-center q-pa-md q-gutter-sm text-h5">
                Il numero di cellulare è gia stato certificato.
            </div>
        </div>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="is_loading"
            style="z-index: 1000;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

    </div>

</template>

<script>
    import commonLib from "@/libs/commonLib"
    import { mapState, mapActions } from "vuex";

    export default {
        name: 'QQOtp',
        data() {
            return {
                disattiva_pulsante_invia_otp: false,
                disattiva_pulsante_verifica_otp: false,
                is_loading: false,
                codice_otp: "",
                verifica_is_visible: false,
                is_otp_valid: false,
                verifica_avviata: false,
                cliente_davanti: false,
                cliente_ricevuto_otp: false,
                otp_inviato: false,
                disattiva_dichiarazioni: false,
                errore_verifica_cellulare: "",
                info_cellulare: {},
                cliente_rappresentante_legale: false,
                rappresentante_legale: ""
            }
        },
        props: {
            title: {
                type: String,
                required: false,
                default: ""
            },
            sub_title: {
                type: String,
                required: false,
                default: ""
            },
            enable_change_num: {
                type: Boolean,
                required: false,
                default: false
            },
            cellulare: {
                type: String,
                required: true
            },
            label_invia_otp: {
                type: String,
                required: false,
                default: "Invia SMS"
            },
            label_verifica: {
                type: String,
                required: false,
                default: "Verifica"
            },
            guid_cliente: {
                type: String,
                required: true
            },
            certificazione: {
                type: Boolean,
                required: false,
                default: false
            }
        /*    onclick: {
                type: Function,
                required: false
            },
            disable: {
                type: Boolean,
                required: false,
                default: false
            } */
        },
        computed: {
            ...mapState({
                formPreventivo: state => state.formPreventivo
            }),
            isSocieta() {
                var tipo_persona = this.formPreventivo.dati_cliente[0].domande.find(d => {
                    return d.name === "tipo_persona";
                });

                return tipo_persona.value === "societa";
            },
            NominativoCliente() {
                if (commonLib.isEmpty(this.formPreventivo)) return "";

                var cognome = this.formPreventivo.dati_cliente[0].domande.find(d => {
                    return d.name === "cognome";
                });
                var nome = this.formPreventivo.dati_cliente[0].domande.find(d => {
                    return d.name === "nome";
                });
                var ragione_sociale = this.formPreventivo.dati_cliente[0].domande.find(d => {
                    return d.name === "ragione_sociale";
                });
                var tipo_persona = this.formPreventivo.dati_cliente[0].domande.find(d => {
                    return d.name === "tipo_persona";
                });

                console.log("Dati: ",cognome,nome,ragione_sociale,tipo_persona);

                switch (tipo_persona.value) {
                    case "professionista":
                    case "persona_fisica":
                        return cognome.value+" "+nome.value;

                    case "societa":
                        return ragione_sociale.value;
                }

                return "";
            },
        },
        methods: {
            ...mapActions({
                verificaFormaleCellulare: "gestioneOTP/verificaFormaleCellulare",
                inviaOTP: "gestioneOTP/inviaOTPPerIdentificazione",
                verificaOTPPerIdentificazione: "gestioneOTP/verificaOTPPerIdentificazione",
                verificaOTPPerfirma: "gestioneOTP/verificaOTPPerfirma",
                infoCellulare: "gestioneOTP/infoCellulare"
            }),
            stato_cellulare() {
                if (commonLib.isEmpty(this.formPreventivo)) return -1; // Mai controllato
                var stato = this.formPreventivo.cellulare_bloccato;
                if (commonLib.isEmpty(stato)) {
                    stato = -1;
                    this.formPreventivo.cellulare_bloccato = -1;
                }

                return stato;
            },

            aggiorna_stato() {
                this.$emit('input', this.is_otp_valid);
            },
            async disattivaPulsanteInviaOtp() {
                if (await this.verificaCellulare()) {
                    this.is_loading                     = true;
                    this.disattiva_pulsante_invia_otp   = true;
                    this.verifica_is_visible            = false;
                    this.verifica_avviata               = false;
                    this.pulsante_verifica_otp          = false;
                    this.otp_inviato                    = false;
                    this.is_otp_valid                   = false;
                    this.disattiva_dichiarazioni        = false;

                    setTimeout(()=>{
                        this.attivaPulsanteInviaOtp();
                    }, 5000);
                    return;
                }

                this.otp_inviato = false;
                var messaggio_errore = "Si e` verificato un errore durante la verifica del cellulare.<br>"+
                                       "L`errore restituito e` il seguente:<br><br>"+
                                       " - "+this.errore_verifica_cellulare+"<br><br>"+
                                       "Verifica prima di continuare";
                this.$q.dialog({
                    title: 'Attenzione',
                    html: true,
                    message: messaggio_errore,
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Ok"
                    }
                });
            },
            async attivaPulsanteInviaOtp() {
                this.is_loading                      = false;
                this.disattiva_pulsante_invia_otp    = false;
                this.disattiva_pulsante_verifica_otp = false;
                // Visualizza editbox per inserire il codice di verifica
                this.verifica_is_visible             = true;
                this.aggiorna_stato();
            },
            disattivaPulsanteVerificaOtp() {
                this.is_loading = true;
                this.disattiva_pulsante_verifica_otp = true;
                this.disattiva_pulsante_invia_otp    = true;
                this.verifica_is_visible             = true;
                this.disattiva_dichiarazioni         = true;
                setTimeout(()=>{
                    this.attivaPulsanteVerificaOtp()
                }, 5000);
            },
            async attivaPulsanteVerificaOtp() {
                this.is_loading                      = false;
                this.disattiva_pulsante_invia_otp    = false;
                this.disattiva_pulsante_verifica_otp = false;
                this.disattiva_dichiarazioni         = true;

                // Visualizza editbox per inserire il codice di verifica
                this.verifica_avviata = true;
                this.aggiorna_stato();
            },
            async onInviaOtp() {
                this.verifica_is_visible = false;
                this.disattivaPulsanteInviaOtp();

                var errore = this.errore_verifica_cellulare+"";

                if (errore !== "") {
                    this.is_otp_valid = false;
                    this.otp_inviato = false;

                    return false;
                }

                var result = await this.inviaOtpAlCliente();

                console.log("onInviaOtp->result:",result);

                if (!result.success) {

                    this.is_otp_valid = false;
                    this.otp_inviato = false;

                    // Si e` verificato un errore
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: result.error,
                        ok: {
                            color: "secondary",
                            flat: false,
                            label: "Ok"
                        }
                    });
                    return false;

                }

                this.verifica_is_visible = true;
                this.otp_inviato = true;

                return true;
            },
            async onVerificaOtp() {
                // Verifica che il codice sia stato inserito
                if (this.codice_otp === "") {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Devi inserire il codice OTP ricevuto prima di continuare.',
                        ok: {
                            color: "secondary",
                            flat: false,
                            label: "Ok"
                        }
                    });

                    this.is_otp_valid = false;

                    return false;
                }

                this.disattivaPulsanteVerificaOtp();

                var result = await this.verificaCodiceOtp();

                console.log("result:",result);

                this.is_otp_valid = result.success;
                this.aggiorna_stato();

                this.errore_verifica_cellulare = result.error;

                return result.success;
            },
            // ------------------------------------------------------------------
            async verificaCellulare() {
                // Chiama end-point sul backend
                if (this.stato_cellulare() === 1) return { esito: false, errore: "Il numero di cellulare "+this.cellulare+" risulta bloccato. " };


                var guid_cliente = this.formPreventivo.IDCliente;
                var cellulare = this.cellulare;
                var result = await this.verificaFormaleCellulare({numero_cellulare: cellulare, guidCliente: guid_cliente});

                if ((commonLib.isEmpty(result)) || (!result)) {
                    this.errore_verifica_cellulare = "Errore durante la verifica formale del cellulare";
                    return false;
                }

                this.errore_verifica_cellulare = result.errore;
                return (result.esito);
            },
            async inviaOtpAlCliente() {
                // Chiama end-point sul backend
                var guid_cliente = this.formPreventivo.IDCliente;
                var cellulare = this.cellulare;
                var result = { success: true, error: "" };
                result = await this.inviaOTP({numero_cellulare: cellulare, guidCliente: guid_cliente});

                return result;
            },
            async verificaCodiceOtp() {
                // Chiama end-point sul backend
                var guid_cliente    = this.formPreventivo.IDCliente;
                var guid_preventivo = this.formPreventivo.preventivo.id;
                var cellulare       = this.cellulare;
                var codice          = this.codice_otp;
                var rappresentante  = this.rappresentante_legale;

                console.log("codice:",codice);
                var result = { success: true, error: "" };
                if (this.certificazione)
                    result = await this.verificaOTPPerIdentificazione({numero_cellulare: cellulare, guidCliente: guid_cliente, codice_otp: codice, rappresentante_legale: rappresentante, guidPreventivo: guid_preventivo });
                else {
                    result = await this.verificaOTPPerfirma({numero_cellulare: cellulare, guidCliente: guid_cliente, guidPreventivo: guid_preventivo, codice_otp: codice});
                }
                return result;

            }
        },
        async mounted() {
            this.disattiva_pulsante_invia_otp = false;
            this.disattiva_pulsante_verifica_otp = false;
            this.cliente_davanti = false;
            this.cliente_ricevuto_otp = false;
            this.otp_inviato = false;
            this.is_otp_valid = false;
            this.codice_otp = "";
            this.verifica_is_visible = false;
            this.disattiva_dichiarazioni = false;
            this.cliente_rappresentante_legale = true;
            if (this.isSocieta) this.cliente_rappresentante_legale = false;

            if (!this.certificazione) {
                // Se non e` una certificazione allora non bisogna flaggare proprio niente
                this.cliente_davanti = true;
                this.cliente_ricevuto_otp = true;
            }

            this.info_cellulare = await this.infoCellulare({
                numero_cellulare: this.cellulare,
                guidCliente: this.guid_cliente,
                guidPreventivo: this.formPreventivo.preventivo.id
            });

            console.log("QQOtp->formPreventivo:",this.formPreventivo);

            if (this.stato_cellulare() === 1) this.info_cellulare.is_bloccato = true;

            if (!commonLib.isEmpty(this.info_cellulare.dati_certificazione)) {
                if (this.certificazione) {
                    this.is_otp_valid = true;
                    this.aggiorna_stato();
                }

            }

            console.log("info_cellulare:",this.info_cellulare, this.is_otp_valid);
        }
    }

</script>
