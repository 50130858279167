<template>
    <div align="center" v-if="is_mounted">
        <!--<div class="text-h4">Invia documenti per emissione</div>
        <br> //-->
        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div class="row justify-center">
        <!--    <div class="col-12 col-md-1"></div> //-->
            <div class="col-12 col-md-5" align="left">
                <h5 class="q-mt-sm q-mb-sm">(1) - Carica i documenti richiesti:</h5>
                <br>
                <ul>
                    <li v-for="(documento,index) in elenco_documenti" v-bind:key="index">
                        {{documento.descrizione_documento}}
                    </li>
                </ul>
                <br>

            </div>

            <div class="col-12 col-md-5" align="center">

                <q-uploader
                    ref="UploadObject"
                    url="https://localhost:1000"
                    color="teal"
                    label="Trascina QUI i documenti da inviare per emettere i contratti"
                    multiple
                    hide-upload-btn
                    name="upload_documenti"
                    :filter="checkFileType"
                    style="max-width: 800px; width: 100%; min-height: 300px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
                    @rejected="onRejected"
                    @added="onAggiungiFileAllegati"
                    @removed="onRimuoviFileAllegati"
                    @uploading="onUploading"
                />
            </div>
        <!--    <div class="col-12 col-md-5"></div> //-->

            <div class="col-12 col-md-12" align="center">
                <br>
                <h5 class="q-mt-sm q-mb-sm">(2) - Firma Dichiarazioni del Contraente:</h5>
                <br>
            </div>


            <div class="col-12 col-md-4" align="center">
                <h6 class="q-mb-sm q-mt-sm text-center">Carica documento firmato manualmente:</h6>
                <br><br>
                <q-uploader
                    ref="UploadDichiarazioniCliente"
                    url="https://localhost:1000"
                    color="teal"
                    label="Trascina QUI il PDF della dichiarazione del Contraente FIRMATA"
                    multiple
                    hide-upload-btn
                    name="upload_dichiarazioni_contraente"
                    :filter="checkFileType"
                    @rejected="onRejected"
                    @uploading="onUploading"
                    style="max-width: 800px; width: 100%; min-height: 300px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"

                />
                <!--
                    @rejected="onRejected"
                    @added="onAggiungiFileAllegati"
                    @removed="onRimuoviFileAllegati"

                //-->
            </div>

            <div class="col-12 col-md-2 justify-center" align="center"><br><br><br><h5>OPPURE</h5></div>

            <div class="col-12 col-md-4 justify-center" align="left">

                <div v-if="otp_identificato">
                    <h6 class="q-mb-sm q-mt-sm text-center">Firma dichiarazioni del Contraente effettuata con successo</h6>
                </div>

                <div v-if="isOtpFirmaDocumentiEnabled && !otp_identificato">

                    <h6 class="q-mb-sm q-mt-sm text-center">Firma dichiarazioni del Contraente con OTP</h6>

                    <div v-if="!info_cellulare.is_certificato">
                        <strong>ATTENZIONE:</strong>
                        <br><br>
                        Firma via OTP non disponibile.<br>
                        <strong>MOTIVO: </strong>Il numero di cellulare non risulta essere certificato per attivare la firma OTP.
                        <br><br>
                        Per certificare un cellulare torna alla pagina precedente.
                    </div>

                    <div v-if="info_cellulare.is_bloccato">
                        <strong>ATTENZIONE:</strong>
                        <br><br>
                        Firma via OTP non disponibile.<br>
                        <strong>MOTIVO: </strong>Il numero di cellulare risulta essere bloccato e non pu&ograve; essere utilizzato per la firma dei documenti.
                        <br><br>
                        Contatta la Sede per stabilire come procedere.
                    </div>

                    <div v-if="!info_cellulare.is_bloccato && info_cellulare.is_certificato" align="center">
                        <br><br>
                        <QQButton label="Effettua la firma OTP"
                            color="blue-grey"
                            icon="mdi-email-check-outline"
                            size="md"
                            @click.native="onVisualizzaModaleOtp"
                        />

                        <q-dialog v-model="visualizza_finestra_otp" persistent>
                            <q-card style="width: 600px;">
                                <q-card-section>
                                <div class="text-h6">Firma della dichiarazione del Contraente con OTP</div>
                                </q-card-section>

                                <q-card-section class="q-pt-none">

                                    <QQOtp
                                        title=""
                                        :cellulare="cellulare_cliente"
                                        :guid_cliente="formPreventivo.IDCliente"
                                        v-model="otp_identificato"
                                    />
                                <!-- Aggiungi certificazione -->
                                </q-card-section>

                                <q-card-actions align="right">
                                    <q-btn label="ESCI" color="secondary" v-close-popup />
                                </q-card-actions>
                            </q-card>
                        </q-dialog>
                    </div>
                </div>

                <div v-if="!isOtpFirmaDocumentiEnabled">
                    <br>
                    <strong>ATTENZIONE</strong>
                    <br>
                    La firma dei documenti con procedura OTP non &egrave; abilitata.
                </div>



            </div>


        </div>

        <br><hr>
        <div class="row" align="center">
            <div class="col-12 col-md-6" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>
            <div class="col-12 col-md-6" align="center">
                <QQButton label="AVANTI" color="blue-grey" icon="mdi-arrow-right-bold" size="md" :disabled="!pulsante_abilitato"
                    @click.native="onUploading"
                />
            </div>
        </div>

        <br><br><br><br><br>

        <q-dialog v-model="show_dialog"
            persistent transition-show="scale"
            transition-hide="scale" >
            <q-card style="width: 500px">
                <q-card-section class="bg-teal text-white">
                <div>TIPO DI DOCUMENTO ALLEGATO:</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <br>
                    <div v-for="(documento,index) in getElencoDocumentiDaSelezionare"
                        v-bind:key="index">
                        <q-checkbox
                            :label="documento.descrizione_documento"
                            type="checkbox"
                            v-model="documento.is_checked"
                        />
                    </div>

                </q-card-section>

                <q-card-actions align="right" class="bg-white text-teal">
                    <q-btn label="OK" v-close-popup color="primary" />
                </q-card-actions>
            </q-card>

        </q-dialog>
    </div>
</template>

<script>
    import axios from 'axios';
    import QQButton from "@/components/QQButton.vue";
    import QQOtp from "@/components/otp/QQOtp.vue";
    //import api from "@/libs/api.js";
    //import commonLib from "@/libs/commonLib.js";
    import { mapState, mapActions } from "vuex";
    import { mapFields} from 'vuex-map-fields';

    export default {
        name: "GestioneDocumenti",
        data() {
            return {
                totale_file_allegati: 0,
                urlDocumentoPreContrattuale: "",
                urlDichiarazioniContraente: "",
                show_dialog: false,
                documenti_allegati: [],
                pulsante_abilitato: true,
                info_cellulare: {},
                isOtpIdentificazioneEnabled: false,
                isOtpFirmaDocumentiEnabled: false,
                visualizza_finestra_otp: false,
                otp_identificato: false,
                cellulare_cliente: "",
                is_mounted: false
            }
        },
        components: {
            QQButton,
            QQOtp
        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo,
                elenco_documenti: state => state.elenco_documenti //,
                //is_loading: state => state.formPreventivo.is_loading
            }),
            ...mapFields("formPreventivo", {
                is_loading: "is_loading",
            }),
            getElencoDocumentiDaSelezionare() {
                var position = this.documenti_allegati.length - 1;
                if (position < 0) return [];

                return this.documenti_allegati[position].value;
            }
        },
        methods: {
            ...mapActions({
                fetchUrlDocumentoPreContrattuale: "gestioneDocumenti/fetchUrlDocumentoPreContrattuale",
                fetchUrlDichiarazioniContraente: "gestioneDocumenti/fetchUrlDichiarazioniContraente",
                //fetchElencoDocumentiDaPreventivo: "gestioneDocumenti/fetchElencoDocumentiDaPreventivo",
                fetchUploadUrl: "gestioneDocumenti/fetchUploadUrl",
                fetchNewGUID: "gestioneDocumenti/fetchNewGUID",
                deleteDocumentoFromDb: "gestioneDocumenti/deleteDocumentoFromDb",

                is_Otp_Identificazione_Enabled: "gestioneOTP/isOtpIdentificazioneEnabled",
                is_otp_firmadocumenti_enabled: "gestioneOTP/isOtpFirmaDocumentiEnabled",
                infoCellulare: "gestioneOTP/infoCellulare",
            }),
            onVisualizzaModaleOtp() {
                this.visualizza_finestra_otp = true;
            },
            VerificaPresenzaTuttiDocumenti() {
                this.elenco_documenti.forEach(documento => {
                    documento.is_checked = false;
                });

                this.documenti_allegati.forEach(documento => {

                    for (var i=0;i<documento.value.length;i++) {
                        var descr_documento = documento.value[i].descrizione_documento;

                        this.elenco_documenti.filter(p => {
                            if (p.descrizione_documento === descr_documento) {
                                p.is_checked = p.is_checked || documento.value[i].is_checked;
                            }
                            return true;
                        });
                    }

                });

                var status = true;

                for (var i=0;i<this.elenco_documenti.length;i++) {
                    status = status && this.elenco_documenti[i].is_checked;
                }

                return status;
            },
            attivaPulsanti() {
                this.pulsante_abilitato = true;
            },
            disattivaPulsanti() {
                this.pulsante_abilitato = false;
                 setTimeout(()=>{
                    this.attivaPulsanti()
                }, 25000);
            },
            VerificaPresenzaDichiarazioniCliente() {
                let files = this.$refs.UploadDichiarazioniCliente.files;

                return (files.length === 1);
            },
            async onUploading() {

                this.disattivaPulsanti();

                let files = this.$refs.UploadObject.files;

                //console.log("files:",files);
                //console.log("files_rinominati:",files_rinominati);

                // controlla se i documenti ci sono tutti
                if (this.VerificaPresenzaTuttiDocumenti()) {

                    //console.log("files:",files);

                    this.is_loading = false;

                    // Invia i documenti al server
                    for( var i = 0; i < files.length; i++ ) {
                        let file = files[i];
                        let formData = new FormData();
                        formData.append('files[' + i + ']', file);

                        var documento = this.documenti_allegati[i];

                        //console.log("info_documento:",documento);

                        // recupera url da S3
                        let url = await this.fetchUploadUrl({IDPreventivo:this.formPreventivo.preventivo.id,
                                                             Filename:file.name,
                                                             InfoDocumento:documento});

                        if (url.error !== "") {
                            this.$q.dialog({
                                title: 'Attenzione',
                                message: url.error
                            });

                            return;
                        }

                        await axios.put(
                            url.data,
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }).then(function(){
                                //console.log('SUCCESS!!');
                            }).catch(function(){
                                //console.log('FAILURE!!');
                            });

                    }

                    this.is_loading = true;

                    // Prima di continuare controlla se la dichiazione del contraente sia stata aggiunta

                    //console.log("isOtpFirmaDocumentiEnabled,otp_identificato:",this.isOtpFirmaDocumentiEnabled,this.otp_identificato)
                    if ((!this.isOtpFirmaDocumentiEnabled) || (!this.otp_identificato)) {
                        var files_dichiarazione = this.$refs.UploadDichiarazioniCliente.files;

                        if (!this.VerificaPresenzaDichiarazioniCliente()) {

                            var errore = "";

                            if (files_dichiarazione.length !== 1) errore = "Sono stati allegati troppi documenti. Devi allegare solo la Dichiarazione del Contraente";
                            if (files_dichiarazione.length === 0) errore = "Manca la Dichiarazione del Contraente FIRMATA dal Cliente"

                            this.$q.dialog({
                                title: 'Attenzione',
                                message: errore
                            });

                            return;
                        } else {
                            var file = files_dichiarazione[0];
                            let formData = new FormData();
                            formData.append('files_dichiarazione[0]', file);

                            //console.log("file:",file);

                            var info_doc = {
                                file_size: file.size,
                                label: file.name,
                                tipo_documento: file.type,
                                value: [
                                    {
                                        descrizione_documento: "Dichiarazioni del Contraente",
                                        is_checked: true
                                    }
                                ],
                                AnnoCreazione: this.documenti_allegati[0].AnnoCreazione,
                                MeseCreazione: this.documenti_allegati[0].MeseCreazione
                            }

                            let url = await this.fetchUploadUrl({IDPreventivo:this.formPreventivo.preventivo.id,
                                                             Filename:file.name,
                                                             InfoDocumento:info_doc});

                            // Invia il documento con axios verso S3
                            await axios.put(
                                url.data,
                                formData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }).then(function(){
                                    //console.log('SUCCESS!!');
                                }).catch(function(){
                                    //console.log('FAILURE!!');
                                });
                        }
                    }

                    // vai alla pagina di conferma
                    this.$router.push({name : "Preventivi.ImpostaDate"});

                } else {
                    // Cerca i documenti mancanti
                    var lista = "";
                    this.elenco_documenti.forEach(documento => {
                        if (!documento.is_checked) {
                            lista += documento.descrizione_documento+', ';
                        }
                    });

                    lista = lista.substring(0,lista.length-2);
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Mancano i seguenti documenti: - '+lista
                    });
                }
            },
            onIndietroClicked() {
                //this.formPreventivo.step = 6;
                //this.$router.push({name : "Preventivi.FormPreventivo", params: { area: this.area }});
                this.$router.push({name : "Preventivi.DownloadDocumenti"});
            },
            checkFileType(files) {
                /*let status = (files.filter(file => file.type === 'application/pdf').length === 1) ||
                             (files.filter(file => file.type === 'application/x-compressed').length === 1) ||
                             (files.filter(file => file.type === 'application/x-zip-compressed').length === 1) ||
                             (files.filter(file => file.type === 'image/png').length === 1) ||
                             (files.filter(file => file.type === 'image/jpeg').length === 1) ||
                             (files.filter(file => file.type === 'image/gif').length === 1) ||
                             (files.filter(file => file.type === 'application/vnd.fdf').length === 1); */

                let status = (files.filter(file => file.type === 'application/pdf').length === 1) ||
                             (files.filter(file => file.type === 'application/vnd.fdf').length === 1);
                if (status) {
                    return files.filter(file => file.type === files[0].type);
                }

                return [];
            },
            onRejected () {
                this.$q.dialog({
                    title: 'Attenzione',
                    message: 'Il tipo di documento allegato può essere solo nel formato PDF,FDF. Gli altri formati non sono accettati per questioni di sicurezza.'
                });
            },
            onAggiungiFileAllegati(files) {
                this.totale_file_allegati += files.length;

                this.elenco_documenti.forEach(p => {
                    p.is_checked = false;
                });

                var name = files[0].name;
                var valori = [];

                for (var i=0;i<this.elenco_documenti.length;i++) {
                    valori[i] = {
                        "descrizione_documento": this.elenco_documenti[i].descrizione_documento,
                        "is_checked": this.elenco_documenti[i].is_checked
                    }
                }

                var create_at = this.formPreventivo.preventivo.create_at;

                var elements = create_at.split("/");

                var documento = {
                    "label": name,
                    "file_size": files[0].size,
                    "tipo_documento": files[0].type,
                    "value": valori,
                    "AnnoCreazione": elements[2],
                    "MeseCreazione": elements[1]
                }

                this.documenti_allegati.push(documento);
                this.show_dialog = true;

            },
            async onRimuoviFileAllegati(files) {
                this.totale_file_allegati -= 1;
                if (this.totale_file_allegati < 0) this.totale_file_allegati = 0;

                // Identifica il record che contiene il documento per cancellarlo dall'array
                var name = files[0].name;
                var IndiceDaCancellare = -1;

                for (var i=0;i<this.documenti_allegati.length;i++) {
                    if (this.documenti_allegati[i].label === name) {
                        IndiceDaCancellare = i;
                    }
                }

                if (IndiceDaCancellare !== -1) {
                    // TODO - il documento cancellato va eliminato anche su DB
                    //console.log("documento:",this.documenti_allegati[IndiceDaCancellare]);
                    //console.log("numero:",this.formPreventivo.preventivo.id);
                    //await this.deleteDocumentoFromDb(this.documenti_allegati[IndiceDaCancellare]);

                    await this.deleteDocumentoFromDb({
                        numero: this.formPreventivo.preventivo.id,
                        documento: this.documenti_allegati[IndiceDaCancellare]
                    });

                    this.documenti_allegati.splice(IndiceDaCancellare,1);
                }

            }
        },
        async mounted() {
            this.is_loading = true;

            window.scrollTo(0,0);

            var cellofono = this.formPreventivo.dati_cliente[0].domande.find(d => {
                return d.name === "cellulare";
            });

            this.cellulare_cliente = cellofono.value;
            var guid_preventivo = this.formPreventivo.preventivo.id;

           // console.log("guid_preventivo:",guid_preventivo,this.cellulare_cliente);

            this.info_cellulare = await this.infoCellulare({numero_cellulare: this.cellulare_cliente,guidCliente: this.formPreventivo.IDCliente, guidPreventivo: guid_preventivo });
            this.isOtpFirmaDocumentiEnabled  = await this.is_otp_firmadocumenti_enabled({numero_cellulare: cellofono,guidCliente: this.formPreventivo.IDCliente});
            this.isOtpIdentificazioneEnabled = await this.is_Otp_Identificazione_Enabled({numero_cellulare: cellofono,guidCliente: this.formPreventivo.IDCliente});

           // console.log("info cellulare:",this.info_cellulare,this.isOtpFirmaDocumentiEnabled,this.isOtpIdentificazioneEnabled );

            this.is_mounted = true;
        }
    }
</script>
